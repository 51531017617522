var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "role-list" },
    [
      _c(
        "app-tree",
        { ref: "appTree", attrs: { opt: _vm.opt } },
        [
          _c(
            "template",
            { slot: "info" },
            [
              _vm.nowRoleId && _vm.isShowSave
                ? _c(
                    "el-button",
                    {
                      staticClass: "role_list_save",
                      on: { click: _vm.saveRoleMenu }
                    },
                    [_vm._v("保存")]
                  )
                : _vm._e(),
              _c(
                "el-tabs",
                {
                  staticClass: "info-box",
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "后台权限", name: "1" } },
                    [
                      _c("el-tree", {
                        ref: "adminTrees",
                        attrs: {
                          "default-expand-all": "",
                          data: _vm.menuData,
                          "default-checked-keys": _vm.opt.defaultChecked,
                          "node-key": "id",
                          "show-checkbox": "",
                          "check-strictly": "",
                          props: {
                            children: "children",
                            label: "label"
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm.isShowWxAuthPane
                    ? _c(
                        "el-tab-pane",
                        { attrs: { label: "小程序权限", name: "2" } },
                        [
                          _c("el-tree", {
                            ref: "wxTrees",
                            attrs: {
                              "default-expand-all": "",
                              data: _vm.menuData,
                              "default-checked-keys": _vm.opt.defaultChecked,
                              "node-key": "id",
                              "show-checkbox": "",
                              props: {
                                children: "children",
                                label: "label"
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "操作权限", name: "3" } },
                    [
                      _vm.isShowAuthPane
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.operationAuthority1,
                                    callback: function($$v) {
                                      _vm.operationAuthority1 = $$v
                                    },
                                    expression: "operationAuthority1"
                                  }
                                },
                                [
                                  _c("span", { staticClass: "checkboxLabel" }, [
                                    _vm._v("排房/退房")
                                  ])
                                ]
                              ),
                              _c("div", { staticClass: "authTitle" }, [
                                _vm._v(
                                  "勾选即表示：支持排房、添加/删除同住人、更换签约主体人、修改排房信息、立即退房、同意退房"
                                )
                              ]),
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.operationAuthority2,
                                    callback: function($$v) {
                                      _vm.operationAuthority2 = $$v
                                    },
                                    expression: "operationAuthority2"
                                  }
                                },
                                [
                                  _c("span", { staticClass: "checkboxLabel" }, [
                                    _vm._v("审核添加/删除同住人")
                                  ])
                                ]
                              ),
                              _c("div", { staticClass: "authTitle" }, [
                                _vm._v(
                                  "勾选即表示：当房东开启了审核添加/删除同住人，当前角色同样支持操作审核添加/删除同住人"
                                )
                              ]),
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.operationAuthority3,
                                    callback: function($$v) {
                                      _vm.operationAuthority3 = $$v
                                    },
                                    expression: "operationAuthority3"
                                  }
                                },
                                [
                                  _c("span", { staticClass: "checkboxLabel" }, [
                                    _vm._v("开锁权限")
                                  ])
                                ]
                              ),
                              _c("div", { staticClass: "authTitle" }, [
                                _vm._v(
                                  "勾选即表示：授权给当前角色的账号和房间，该角色支持开房间门锁"
                                )
                              ]),
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.operationAuthority4,
                                    callback: function($$v) {
                                      _vm.operationAuthority4 = $$v
                                    },
                                    expression: "operationAuthority4"
                                  }
                                },
                                [
                                  _c("span", { staticClass: "checkboxLabel" }, [
                                    _vm._v("批量远程开锁权限")
                                  ])
                                ]
                              ),
                              _c("div", { staticClass: "authTitle" }, [
                                _vm._v(
                                  "勾选即表示：授权当前角色登录手机小程序远程批量开锁权限"
                                )
                              ])
                            ],
                            1
                          )
                        : _c("div", { staticClass: "notAuthData" }, [
                            _vm._v("暂无数据")
                          ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.roleText,
                    visible: _vm.dialogVisibleAddRole,
                    center: ""
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.dialogVisibleAddRole = $event
                    }
                  }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入角色名称" },
                    model: {
                      value: _vm.roleName,
                      callback: function($$v) {
                        _vm.roleName =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "roleName"
                    }
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.submit()
                            }
                          }
                        },
                        [_vm._v("提交")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }