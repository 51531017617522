<template>
  <div class="role-list">
    <app-tree :opt="opt" ref="appTree">
      <template slot="info">
        <el-button class="role_list_save" @click="saveRoleMenu" v-if="nowRoleId && isShowSave">保存</el-button>
        <el-tabs v-model="activeName" class="info-box">
          <el-tab-pane label="后台权限" name="1">
            <el-tree default-expand-all ref="adminTrees" :data="menuData" :default-checked-keys="opt.defaultChecked"
              node-key="id" show-checkbox check-strictly :props="{
                children: 'children',
                label: 'label'
              }"></el-tree>
          </el-tab-pane>
          <el-tab-pane label="小程序权限" name="2" v-if="isShowWxAuthPane">
            <el-tree default-expand-all ref="wxTrees" :data="menuData" :default-checked-keys="opt.defaultChecked"
              node-key="id" show-checkbox :props="{
                children: 'children',
                label: 'label'
              }"></el-tree>
          </el-tab-pane>
          <el-tab-pane label="操作权限" name="3">
            <div v-if="isShowAuthPane">
              <el-checkbox v-model="operationAuthority1"><span class="checkboxLabel">排房/退房</span></el-checkbox>
              <div class="authTitle">勾选即表示：支持排房、添加/删除同住人、更换签约主体人、修改排房信息、立即退房、同意退房</div>
              <el-checkbox v-model="operationAuthority2"><span class="checkboxLabel">审核添加/删除同住人</span></el-checkbox>
              <div class="authTitle">勾选即表示：当房东开启了审核添加/删除同住人，当前角色同样支持操作审核添加/删除同住人</div>
              <el-checkbox v-model="operationAuthority3"><span class="checkboxLabel">开锁权限</span></el-checkbox>
              <div class="authTitle">勾选即表示：授权给当前角色的账号和房间，该角色支持开房间门锁</div>
              <el-checkbox v-model="operationAuthority4"><span class="checkboxLabel">批量远程开锁权限</span></el-checkbox>
              <div class="authTitle">勾选即表示：授权当前角色登录手机小程序远程批量开锁权限</div>
            </div>
            <div v-else class="notAuthData">暂无数据</div>
          </el-tab-pane>
        </el-tabs>
        <el-dialog :title="roleText" :visible.sync="dialogVisibleAddRole" center>
          <el-input v-model.trim="roleName" placeholder="输入角色名称"></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="submit()">提交</el-button>
          </span>
        </el-dialog>
      </template>
    </app-tree>
  </div>
</template>
<script>
  export default {
    data() {
      let _this = this;
      return {
        operationAuthority1: false,
        operationAuthority2: false,
        operationAuthority3: false,
        operationAuthority4: false,
        isShowSave: false,
        isShowWxAuthPane: true,
        isShowAuthPane: false, //操作权限
        dialogVisibleAddRole: false,
        roleName: "",
        roleText: "新增角色",
        nowRoleId: "", // 当前选中角色id
        opt: {
          title: "权限管理",
          defaultChecked: [],
          on(data) {
            _this.post("/role/detail").then(data => {
              _this.form.name = data.name;
              _this.form.parent = data.parent;
              _this.form.typeStr = ["系统管理员", "普通用户"][data.type - 1];
              _this.form.datetimeStr = _this.format(data.datetime);
              _this.form.des = data.des;
            });
          },
          table: {
            isNotShowSelection: true,
            isShowHighliht: true,
            columns: [{
              label: "角色名称",
              key: "roleName"
            }],
            buttons: [{
                type: 0,
                on() {
                  _this.dialogVisibleAddRole = true;
                  _this.roleText = "新增角色";
                  _this.roleName = "";
                  _this.nowRoleId = "";
                }
              },
              {
                type: 1,
                on(row) {
                  _this.dialogVisibleAddRole = true;
                  _this.roleText = "编辑角色";
                  _this.roleName = row.data.roleName;


                }
              },
            ],
            get(opt) {
              _this
                .post(
                  "user-service/user/v1/housekeeper-role/list", {
                    roleType: '3'
                  }, {
                    contentType: "application/x-www-form-urlencoded",
                    isUseResponse: true
                  }
                )
                .then(res => {
                  _this.menuData = [];
                  _this.form = {};
                  _this.isShowAuthPane = false;
                  opt.cb(res.data);
                });
            },
            onRow(data) {
              _this.nowRoleId = data.id;
              //如果是房管员，则不显示小程序权限
              // if (_this.nowRoleId == 6) {
              //   _this.isShowWxAuthPane = false;
              //   _this.activeName = "1";
              //   _this.getAdminAouth();
              // } else {
              //   _this.isShowWxAuthPane = true; 
              //   if (_this.activeName == "1") {
              //     _this.getAdminAouth();
              //   } else {
              //     _this.getWxAouth();
              //   }
              // }
              _this.isShowWxAuthPane = true;
              _this.isShowAuthPane = true;
              if (_this.activeName == "1") {
                _this.getAdminAouth();
              } else if (_this.activeName == "2") {
                _this.getWxAouth();
              } else {
                _this.getAouth()
              }
            }
          }
        },
        activeName: "1",
        // 操作权限列表
        menuData: []
      };
    },
    watch: {
      activeName(data) {
        if (this.nowRoleId != "") {
          if (data == "1") {
            this.getAdminAouth();
          } else if (data == "2") {
            this.getWxAouth();
          } else {
            this.getAouth();
          }
        }
      }
    },
    methods: {
      // 获取操作权限
      getAouth() {
        let _this = this;
        let dto = {
          roleId: _this.nowRoleId
        }
        _this.post("/user-service/user/v1/housekeeper-role/detail", dto)
          .then(res => {
            if (!res.authorityCodeList || res.authorityCodeList.length == 0) {
              _this.operationAuthority1 = false;
              _this.operationAuthority2 = false;
              _this.operationAuthority3 = false;
              _this.operationAuthority4 = false;
            }
            if (res.authorityCodeList.indexOf('plan_quit') != -1) {
              _this.operationAuthority1 = true;
            } else {
              _this.operationAuthority1 = false;
            }
            if (res.authorityCodeList.indexOf('examine_add_delete') != -1) {
              _this.operationAuthority2 = true;
            } else {
              _this.operationAuthority2 = false;
            }
            if (res.authorityCodeList.indexOf('open_lock') != -1) {
              _this.operationAuthority3 = true;
            } else {
              _this.operationAuthority3 = false;
            }
            if (res.authorityCodeList.indexOf('batch_remote_open') != -1) {
              _this.operationAuthority4 = true;
            } else {
              _this.operationAuthority4 = false;
            }
          })
      },
      // 获取后台权限
      getAdminAouth() {
        let _this = this;
        let menuGetList = [];
        _this.post("user-service/menu/queryLandlordToSubMenu").then(resData => {
          menuGetList = resData;
          // 获取操作权限
          _this.post("user-service/apartmentuser/queryAouth", {
              roleId: _this.nowRoleId
            })
            .then(res => {
              if (!res) {
                res = [];
              }
              let nowRoleId = [];
              res = JSON.parse(JSON.stringify(res).replace(/menuId/g, "id"));
              menuGetList = JSON.parse(
                JSON.stringify(menuGetList).replace(/menuId/g, "id")
              );
              menuGetList = JSON.parse(
                JSON.stringify(menuGetList).replace(/title/g, "label")
              );
              menuGetList = JSON.parse(
                JSON.stringify(menuGetList).replace(/childMenus/g, "children")
              );
              res.forEach(item => {
                nowRoleId.push(item.id);
                if (item.children && item.children.length) {
                  item.children.forEach(datas => {
                    nowRoleId.push(datas.id);
                    if (datas.children && datas.children.length) {
                      nowRoleId.push(datas.parentId);
                      datas.children.forEach(childrenItem => {
                        nowRoleId.push(childrenItem.parentId);
                        nowRoleId.push(childrenItem.id);
                      });
                    }
                  });
                }
              });
              _this.menuData = menuGetList;
              _this.opt.defaultChecked = nowRoleId;
            });
        });
      },
      // 获取后台权限
      getWxAouth() {
        let _this = this;
        let menuGetList = [];

        _this
          .post("user-service/menu/queryWxallmenu", {
            roleId: "2"
          })

          .then(resData => {
            menuGetList = resData;
            // 获取操作权限
            _this
              .post("user-service/apartmentuser/queryWxAouth", {
                roleId: _this.nowRoleId
              })
              .then(res => {
                if (!res) {
                  res = [];
                }
                let nowRoleId = [];
                res = JSON.parse(JSON.stringify(res).replace(/menuId/g, "id"));
                menuGetList = JSON.parse(
                  JSON.stringify(menuGetList).replace(/menuId/g, "id")
                );
                menuGetList = JSON.parse(
                  JSON.stringify(menuGetList).replace(/title/g, "label")
                );
                menuGetList = JSON.parse(
                  JSON.stringify(menuGetList).replace(/childMenus/g, "children")
                );
                res.forEach(item => {
                  nowRoleId.push(item.id);
                  if (item.children && item.children.length) {
                    item.children.forEach(datas => {
                      nowRoleId.push(datas.id);
                      if (datas.children && datas.children.length) {
                        nowRoleId.push(datas.parentId);
                        datas.children.forEach(childrenItem => {
                          nowRoleId.push(childrenItem.parentId);
                          nowRoleId.push(childrenItem.id);
                        });
                      }
                    });
                  }
                });
                _this.menuData = menuGetList;
                _this.opt.defaultChecked = nowRoleId;
              });
          });
      },
      // 提交 添加/编辑角色
      submit() {
        if (!this.roleName) {
          return this.$message({
            message: '请输入角色名',
            type: 'warning',
          })
        }
        let url;
        let dto = {
          roleName: this.roleName
        }
        let message;
        if (this.nowRoleId) {
          url = "user-service/user/v1/housekeeper-role/update";
          dto.roleId = this.nowRoleId;
          message = '编辑角色成功';
        } else {
          url = "user-service/user/v1/housekeeper-role/add";
          message = '添加角色成功';
        }
        this.post(url, dto, {
          isUseResponse: true
        }).then(res => {
          if (res.data.code == 0) {
            this.dialogVisibleAddRole = false;
            this.$refs.appTree.initList();
            return this.$message({
              message: message,
              type: 'success',
            })
          }

        })
      },
      // 权限保存
      saveRoleMenu() {
        if (!this.nowRoleId) {
          this.$message({
            showClose: true,
            message: "请选择角色",
            type: "warning"
          });
          return;
        }
        let url;
        let dto = {};
        if (this.activeName == 3) { //操作权限
          let operationList = [];
          if (this.operationAuthority1) {
            operationList.push('plan_quit');
          }
          if (this.operationAuthority2) {
            operationList.push('examine_add_delete');
          }
          if (this.operationAuthority3) {
            operationList.push('open_lock');
          }
          if (this.operationAuthority4) {
            operationList.push('batch_remote_open');
          }
          url = "user-service/user/v1/housekeeper-role/authority";
          dto = {
            roleId: this.nowRoleId,
            authorityList: operationList
          }
        } else { //后台权限和小程序权限
          let dom = this.activeName == "1" ? this.$refs.adminTrees : this.$refs.wxTrees;
          let nowMenu = dom.getCheckedKeys();
          url = "user-service/role/landlordUpdateRoleMenu";
          nowMenu.forEach(data => {
            data = data.toString();
          });
          dto = {
            roleId: this.nowRoleId,
            menuIdList: nowMenu,
            platType: this.activeName == "1" ? "0" : "1"
          }
        }


        this.post(url, dto).then(data => {
          this.$message({
            showClose: true,
            message: "保存成功!",
            type: "success"
          });
          //编辑操作权限保存之后请求接口更新数据
          if (this.activeName == 3) {
            this.getAouth();
          }
        });
      },
    },

    activated() {
      console.log("role-list activated!!");
      // this.$refs.appTree.initList();
      this.nowRoleId = "";
      let menuGetList = this.cache.getLS("userInfo")["userMenuInfos"];
      menuGetList.forEach(item => {
        if (item.menuId == 25 && item.childMenus.length) {
          item.childMenus.forEach(items => {
            if (items.menuId == 27 && items.childMenus.length) {
              this.isShowSave = true;
            }
          });
        }
      });
      this.roleName = "";
      // 初始化操作权限数据
      this.operationAuthority1 = false;
      this.operationAuthority2 = false;
      this.operationAuthority3 = false;
      this.operationAuthority4 = false;
    },
    deactivated() {
      this.dialogVisibleAddRole = false;
    },
  };
</script>
<style lang="scss" scoped>
  .role_list_save {
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 1111;
  }

  .info-box .el-tabs__header {
    margin: 0;
  }

  .info-box .el-tabs__content {
    height: 420px;
    overflow-y: auto;
  }

  .notAuthData {
    text-align: center;
    color: #909399;
    font-size: 14px;
    padding-top: 21px;
  }

  .authTitle {
    padding-left: 20px;
    padding-bottom: 10px;
    padding-top: 4px;
    color: #606266;
    font-size: 14px;
  }

  .checkboxLabel {
    color: #000000;
    font-size: 15px;
    font-weight: 700;
  }
</style>